<template>
	<div class="rechargeRecord">
		<!-- 缴费记录 -->
		<ul class="table-box payment-table">
			<li class="table-head">
				<div class="table-title">缴费记录</div>
				<div class="table-colums">
					<span
						v-for="item in payColums"
						:key="item.key"
					>
						{{ item.title }}
					</span>
				</div>
			</li>
			<li class="table-data">
				<ul
					v-for="(item, index) in paymentRecords"
					:key="index"
					:class="{'table-item': true, 'black-bg': index % 2 === 1 }">
					<li
						v-for="(keyName, kIndex) in item"
						:key="kIndex">
						<span> {{ keyName }} </span>
					</li>
				</ul>
			</li>
		</ul>

		<!-- 抄表记录 -->
		<ul class="table-box meter-table">
			<li class="table-head">
				<div class="table-title">抄表记录</div>
				<div class="table-colums">
					<span
						v-for="item in meterColums"
						:key="item.key"
						:title="item.title"
					>
						{{ item.title }}
					</span>
				</div>
			</li>
			<li class="table-data">
				<ul
					v-for="(item, index) in meterRecords"
					:key="index"
					:class="{'table-item': true, 'black-bg': index % 2 === 1 }">
					<li
						v-for="(keyName, kIndex) in item"
						:key="kIndex">
						<span> {{ keyName }} </span>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
const payColums = [
	{
		title: '缴费日期',
		key: 'payTime'
	},
	{
		title: '缴费金额',
		key: 'payAccount'
	},
	{
		title: '收款员',
		key: 'receivingTeller'
	},
	{
		title: '本次扣款',
		key: 'withhold'
	},
	{
		title: '本次余额',
		key: 'balance'
	}
]

const paymentRecords = [];
for (let i = 0; i < 20; i++) {
  paymentRecords.push({
    // id: i,
    payTime: '2022-03-11',
    payAccount: '￥100',
    receivingTeller: '微信',
    withhold: '￥90',
    balance: '￥13.5'
  });
}
const meterColums = [
	{
		title: '用户编号',
		key: 'clientCode'
	},
	{
		title: '抄表日期',
		key: 'readMeterTime',
	},
	{
		title: '抄表员',
		key: 'meterReader',
	},
	{
		title: '起止码',
		key: 'startStopCode',
	},
	{
		title: '是否已缴',
		key: 'isPayed',
	},
	{
		title: '计费水量',
		key: 'waterBilling',
	},
	{
		title: '应收金额',
		key: 'receivableAmount',
	},
]

const meterRecords = [];
for (let i = 0; i < 20; i++) {
  meterRecords.push({
    // id: i,
    clientCode: '22121',
    readMeterTime: '2022-03-11',
    meterReader: '张三',
    startStopCode: '1050-1450',
		isPayed: '否',
    waterBilling: '40000000000000000',
		receivableAmount: '￥550',
  });
}

export default {
	data() {
		return {
			payColums,
			paymentRecords,
			meterColums,
			meterRecords
		}
	}
}
</script>

<style lang="less" scoped>
.rechargeRecord {
	width: 100%;
	height: 100vh;
	color: #333;
	font-size: 12px;
	background-color: #fff;
	
	.table-box {
		padding: 15px;
		box-sizing: border-box;
		height: 50%;
		
		.table-head {
			height: 70px;
			font-weight: 700;
			.table-title {
				font-size: 16px;
				text-align: center;
			}
			.table-colums {
				padding-top: 20px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.table-data {
			height: calc(227px - 70px);
			overflow: auto;
			.table-item {
				margin-top: 10px;
				display: flex;
				align-items: center;
				height: 25px;
				line-height: 25px;
				&.black-bg {
					// background: rgba(0, 17, 58, 0.3);
					background-color: #F5F5F5;
				}
				li > span {
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					white-space: nowrap; //溢出则不换行
				}
			}
		}
	}

	.payment-table {
		.table-colums > span:first-child {
			display: inline-block;
			width: 15%;
			text-align: center;
		}
		.table-item {
			justify-content: space-between;
			li {
				width: 20%;
				text-align: center;
				&:first-child {
					text-align: left;
				}
				&:last-child > span {
					padding-left: 15px;
				}
			}
		}
	}

	.meter-table {
		// white-space: nowrap;
		// overflow-x: auto;
		.table-colums {
			// display: flex;
			// white-space: nowrap;
			// overflow-x: auto;
			span:nth-child(3), span:nth-child(4) {
				padding-left: 5px;
			}
			span:nth-child(5) {
				padding-left: 10px;
			}
		}
		.table-item {
			li {
				width: 14.29%;
				text-align: center;
			}
			li:first-child, li:nth-child(4) {
				text-align: left;
			}
			li:nth-child(3) {
				padding-left: 5px;
			}
		}
	}
}
</style>