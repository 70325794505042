<template>
  <div v-if="dataInfo" class="account">
    <div class="account-header">
      <account-user :dataInfo="dataInfo" />
    </div>
    <div class="account-main">
      <div class="account-main-customer">
        <van-cell v-if="dataInfo.isWaterUser" @click="gotoBindCustomer">
          <div class="amc-bind">
            <div>
              <van-image width="44px" height="44px" fit="cover" :src="imgBind" />
              <span class="amc-title">已绑客户</span>
            </div>
            <div>
              <span class="amc-number">{{ bindCustomerNumber }}人</span>
              <van-image width="44px" height="44px" fit="cover" :src="imgArrow" />
            </div>
          </div>
        </van-cell>
<!--        <van-cell @click="gotoInvoice">
          <div class="amc-bind">
            <div>
              <van-image width="44px" height="44px" fit="cover" :src="imgInvoice" />
              <span class="amc-title">电子发票</span>
            </div>
            <div>
              <van-image width="44px" height="44px" fit="cover" :src="imgArrow" />
            </div>
          </div>
        </van-cell>-->
      </div>
      <div class="account-main-tag">
        <van-cell>
          <label-tag :value="tagValue" labelWidth="50px" @getTagValue="getTagValueInfo" />
        </van-cell>
      </div>
      <div class="account-main-line">
        <!-- <line-chart chartId="equip-chart" :option="chartOption" /> -->
        <!-- <table-record></table-record> -->

        <ul class="table-box payment-table" v-if="paymentRecords.length">
          <li class="table-head">
            <div class="table-title">缴费记录</div>
            <div class="table-colums">
              <span
                v-for="item in payColums"
                :key="item.key"
              >
                {{ item.title }}
              </span>
            </div>
          </li>
          <li class="table-data">
            <ul
              v-for="(item, index) in paymentRecords"
              :key="index"
              :class="{'table-item': true, 'black-bg': index % 2 === 1 }"
              @click="getCurrentRow(item, true)"
            >
              <li
                v-for="(keyName, kIndex) in item"
                :key="kIndex">
                <span> {{ keyName }} </span>
              </li>
            </ul>
          </li>
        </ul>

        <div v-else class="no-data-box">
          <div>缴费记录</div>
          <no-data />
        </div>
      </div>

      <div class="account-main-line">
        <ul class="table-box meter-table" v-if="meterRecords.length">
          <li class="table-head">
            <div class="table-title">抄表记录</div>
            <div class="table-colums">
              <span
                v-for="item in meterColums"
                :key="item.key"
                :title="item.title"
              >
                {{ item.title }}
              </span>
            </div>
          </li>
          <li class="table-data">
            <ul
              v-for="(item, index) in meterRecords"
              :key="index"
              :class="{'table-item': true, 'black-bg': index % 2 === 1 }"
              @click="getCurrentRow(item, false)"
            >
              <li
                v-for="(keyName, kIndex) in item"
                :key="kIndex"
              >
                <span> {{ keyName }} </span>
              </li>
            </ul>
          </li>
        </ul>

        <div v-else class="no-data-box">
          <div>抄表记录</div>
          <no-data />
        </div>
      </div>

      <van-popup
        v-model:show="showRecords"
        :style="{ height: `${isPayment ? '35%' : '40%'}` }"
      >
        <ul class="records-box">
          <li>
            <span
              class="records-label"
              v-for="(item, index) in currentLabel"
              :key="index"
            >
              {{ item + '：' }}
            </span>
          </li>
          <li>
            <span
              class="records-item"
              v-for="(keyName, kIndex) in currentRow"
              :key="kIndex"
            >
              {{ (keyName === 'string' && !keyName) ? '无' : keyName }}
            </span>
          </li>
        </ul>
      </van-popup>
    </div>
    <div class="account-foot">
      <van-button class="account-foot-relieve" @click="handerUnbind">解除绑定</van-button>
    </div>
  </div>
</template>

<script>
const payColums = [
	{
		title: '缴费日期',
		key: 'payTime'
	},
	{
		title: '缴费金额',
		key: 'cciPayAmount'
	},
	{
		title: '收款员',
		key: 'cciChargeStaffName'
	},
	{
		title: '本次扣款',
		key: 'cciFact'
	},
	{
		title: '本次余额',
		key: 'cciPrestoreBalance'
	}
]

const paymentRecords = [];
for (let i = 0; i < 20; i++) {
  paymentRecords.push({
    // id: i,
    payTime: '2022-03-11',
    cciPayAmount: '￥100',
    cciChargeStaffName: '微信',
    cciFact: '￥90',
    cciPrestoreBalance: '￥13.5'
  });
}

const meterColums = [
	{
		title: '用户编号',
		key: 'meterCode'
	},
	{
		title: '抄表日期',
		key: 'chargeCheckTime',
	},
	// {
	// 	title: '抄表员',
	// 	key: 'chargeCheckStaff',
	// },
	{
		title: '起止码',
		key: 'startToEnd',
	},
	{
		title: '是否已缴',
		key: 'chargeCancelState',
	},
	{
		title: '计费水量',
		key: 'chargeCount',
	},
	{
		title: '应收金额',
		key: 'chargeAmount',
	},
]

const meterRecords = [];
for (let i = 0; i < 20; i++) {
  meterRecords.push({
    // id: i,
    meterCode: '22121',
    chargeCheckTime: '2022-03-11',
    chargeCheckStaff: '张三',
    startToEnd: '1050-1450',
		chargeCancelState: '否',
    chargeCount: '40000000000',
		chargeAmount: '￥550',
  });
}

import { Dialog } from 'vant';
import AccountUser from './module/AccountUser';
import TableRecord from './module/TableRecord';
import NoData from '@/components/noData';
import LineChart from '@/components/chart/LineChart';
import LabelTag from '@/components/labelTag';
import { formatTime } from '@/utils/tools';
import { chartOption } from './module/chartConfig';
const ImgBind = require('../../assets/icon/icon_绑定.png');
const ImgArrow = require('../../assets/icon/icon_箭头.png');
const ImgInvoice = require('../../assets/icon/icon_发票.png');

import {
  selectOneWaterStatistics,
  selectWuserNoCust,
  updateLabel,
  unbindUser,
  selectRechargeRecord
} from '../../api/home';
import { Toast } from 'vant';

export default {
  components: {
    AccountUser,
    LineChart,
    LabelTag,
    TableRecord,
    NoData
  },
  data() {
    return {
      imgBind: ImgBind,
      imgArrow: ImgArrow,
      imgInvoice: ImgInvoice,
      chartOption,
      bindCustomerNumber: '',
      tagValue: this.$route.query.labelName,
      labelName: this.$route.query.labelName,
      wuserNo: this.$route.query.wuserNo,
      custWaterUserRelId: this.$route.query.custWaterUserRelId,
      // isWaterUser: false,
      dataInfo: null,
      payColums,
      paymentRecords: [],
      meterColums,
      meterRecords: [],
      showRecords: false,
      currentRow: null,
      currentLabel: [],
      isPayment: true
    };
  },
  mounted() {
    // this.isWaterUser = this.$route.query  && this.$route.query.isWaterUser === true ? true : false;
    /* this.chartOption.series = [
      {
        name: '今年',
        type: 'line',
        symbolSize: 4,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: this.chartOption.color[0] },
              { offset: 1, color: 'rgba(255,255,255,0)' },
            ],
            global: false, // 缺省为 false
          },
        },
        data: [],
      },
      {
        name: '去年',
        type: 'line',
        symbolSize: 4,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: this.chartOption.color[1] },
              { offset: 1, color: 'rgba(255,255,255,0)' },
            ],
            global: false, // 缺省为 false
          },
        },
        data: [],
      },
      {
        name: '前年',
        type: 'line',
        symbolSize: 4,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: this.chartOption.color[2] },
              { offset: 1, color: 'rgba(255,255,255,0)' },
            ],
            global: false, // 缺省为 false
          },
        },
        data: [],
      },
    ]; */

    // this.initFn();

    this.getPayMentData();
    this.getMeterData();
    this.getSelectAccount();
  },

  methods: {
    // 获取缴费记录
    async getPayMentData() {
      const { status, resultData } = await selectRechargeRecord(this.wuserNo);
      if (status === 'complete') {
        this.paymentRecords = resultData.map(item => {
          let obj = {
            payTime: item.payTime,
            cciPayAmount: `￥${item.cciPayAmount}`,
            cciChargeStaffName: item.cciChargeStaffName,
            cciFact: `￥${item.cciFact}`,
            cciPrestoreBalance: item.cciPrestoreBalance,
          }
          return obj;
        });
      }
    },
    // 获取抄表记录
    async getMeterData() {
      const custWaterUserRelId = this.custWaterUserRelId;
      const { status, resultData } = await selectOneWaterStatistics(custWaterUserRelId);
      if (status === 'complete') {
        this.tagValue = resultData.labelName;
        this.labelName = resultData.labelName;
        this.wuserNo = resultData.wuserNo;
        this.dataInfo = resultData;
        this.meterRecords = resultData.accountMeterReadList.map(item => {
          let obj = {
            meterCode: item.meterCode,
            chargeCheckTime: item.chargeCheckTime,
            // chargeCheckStaff: item.chargeCheckStaff,
            startToEnd: item.startToEnd,
            chargeCancelState: item.chargeCancelState === 'Y' ? '是' : item.chargeCancelState === 'N' ? '否' : '',
            chargeCount: item.chargeCount,
            chargeAmount: `￥${item.chargeAmount}`,
          }
          return obj;
        });
      }
    },
    getCurrentRow(row, isPayment) {
      this.isPayment = isPayment;
      const payLabel = ['缴费日期', '缴费金额', '收款员', '本次扣款', '本次余额'];
      const meterLabel = ['用户编号', '抄表日期', '起止码', '是否已缴', '计费水量', '应收金额'];
      this.currentLabel = isPayment ? payLabel : meterLabel;
      this.currentRow = JSON.parse(JSON.stringify(row));
      this.showRecords = true;
    },
    // 页面请求
    /* async initFn() {
      console.log('1111111111');
      const custWaterUserRelId = this.custWaterUserRelId;
      const { status, resultData } = await selectOneWaterStatistics(custWaterUserRelId);
      if (status === 'complete') {
        // 转换时间
        resultData.finalPayTime = formatTime(resultData.finalPayTime);
        this.tagValue = resultData.labelName;
        this.labelName = resultData.labelName;
        this.wuserNo = resultData.wuserNo;
        this.dataInfo = resultData;
        console.log('传之前', resultData);

        this.chartOption.xAxis[0].data = [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月',
        ];
        this.chartOption.legend.data = ['今年', '去年', '前年'];
        // 今年数据
        let thisYearListData = [];
        resultData &&
          resultData.thisYearList.map((item) => {
            for (let i in item) {
              thisYearListData.push(item[i]);
            }
          });
        // 去年数据
        let lastYearListData = [];
        resultData &&
          resultData.lastYearList.map((item) => {
            for (let i in item) {
              lastYearListData.push(item[i]);
            }
          });
        // 前年数据
        let beforeLastYearListData = [];
        resultData &&
          resultData.beforeLastYearList.map((item) => {
            for (let i in item) {
              beforeLastYearListData.push(item[i]);
            }
          });
        this.chartOption.series[0].data = thisYearListData;
        this.chartOption.series[1].data = lastYearListData;
        this.chartOption.series[2].data = beforeLastYearListData;
      }
    }, */
    // 获取已绑定客户数量
    async getSelectAccount() {
      console.log('222');
      let arg = {
        custWaterUserRelId: this.custWaterUserRelId,
      };
      const { status, resultData } = await selectWuserNoCust(arg);
      if (status === 'complete') {
        this.bindCustomerNumber = resultData.list.length;
      }
    },
    // 修改标签
    getTagValueInfo(msg) {
      this.labelName = msg;
      this.updateLabel();
    },
    async updateLabel() {
      const arg = {
        custWaterUserRelId: this.custWaterUserRelId,
        labelName: this.labelName,
      };
      const { status, resultData } = await updateLabel(arg);
      if (status === 'complete') {
        Notify({ type: 'success', message: '修改成功' });
      }
    },
    // 解除绑定
    handerUnbind() {
      if (this.dataInfo.labelName && this.dataInfo.labelName.length > 0) {
        let that = this;
        Dialog.confirm({
          message: '解除绑定后将无法查看该用水账户的信息，您确定解除绑定吗？',
          confirmButtonText: '解除绑定',
          confirmButtonColor: '#3C7EF0',
          cancelButtonText: '不解除',
          cancelButtonColor: '#767E8C',
          className: 'removeDialog',
        })
          .then(() => {
            console.log('解除');
            const realUserId = this.dataInfo.custRealId;
            const wuserNo = this.dataInfo.wuserNo;
            that.unbindUser(realUserId, wuserNo);
          })
          .catch(() => {
            console.log('取消');
          });
      } else {
        Toast('请选择标签');
      }
    },
    async unbindUser(realUserId, wuserNo) {
      const { status, resultData } = await unbindUser(realUserId, wuserNo);
      if (status === 'complete') {
        Toast('已解除该客户的绑定');
        this.$router.push({ path: '/' });
      }
    },
    gotoBindCustomer() {
      this.$router.push({
        name: 'BindCustomer',
        path: '/WaterAccount/bindCustomer',
        query: {
          custWaterUserRelId: this.custWaterUserRelId,
        },
      });
    },
    gotoInvoice() {
      this.$router.push({
        name: 'Invoice',
        path: '/Invoice',
        query: {
          wuserNo: this.wuserNo,
        },
      });
    },
  },
};
</script>

<style lang="less">
.account {
  width: 100%;
  height: 100vh;
  &-header {
    margin: 16px 8px 0 8px;
  }
  &-main {
    height: 88%;
    &-customer {
      border-radius: 8px;
      overflow: hidden;
      margin: 16px 8px 0 8px;
      .van-cell {
        padding: 6px 0 6px 16px;
      }
      .amc-bind {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          align-items: center;
        }
        .amc-title {
          margin-left: 18px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #333333;
        }
        .amc-number {
          margin-right: 10px;
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: #4a90e2;
        }
      }
    }
    &-tag {
      background: #ffffff;
      margin: 16px 8px 0 8px;
      border-radius: 8px;
      overflow: hidden;
    }
    &-line {
      border-radius: 8px;
      // overflow: hidden;
      // padding: 16px;
      margin: 16px 8px;
      background: #ffffff;
      height: 35%;
      color: #333;
	    font-size: 12px;
    }
  }
  &-foot {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    &-relieve {
      width: 100%;
      margin: 0 16px;
      background: #ffffff;
      border-radius: 8px;
      border: none;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .table-box {
    height: 100%;
		padding: 15px;
		box-sizing: border-box;
		
		.table-head {
			height: 70px;
			font-weight: 700;
			.table-title {
				font-size: 16px;
				text-align: center;
			}
			.table-colums {
				padding-top: 15px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.table-data {
			height: calc(100% - 70px);
			overflow: auto;
			.table-item {
				margin-top: 10px;
				display: flex;
				align-items: center;
				height: 25px;
				line-height: 25px;
				&.black-bg {
					// background: rgba(0, 17, 58, 0.3);
					background-color: #F5F5F5;
				}
				li, span {
					overflow: hidden; //超出的文本隐藏
					text-overflow: ellipsis; //溢出用省略号显示
					white-space: nowrap; //溢出则不换行
				}
			}
		}
	}

	.payment-table {
		.table-colums > span:first-child {
			display: inline-block;
			width: 15%;
			text-align: center;
		}
		.table-item {
			justify-content: space-between;
			li {
				width: 20%;
				text-align: center;
				&:first-child {
					text-align: left;
				}
				&:last-child > span {
					padding-left: 15px;
				}
			}
		}
	}

  .meter-table {
		.table-colums {
			span:nth-child(3), span:nth-child(4) {
				padding-left: 12px;
			}
			span:nth-child(5) {
				padding-left: 10px;
			}
		}
		.table-item {
      justify-content: space-between;
			li {
				// width: 14.29%;
        width: 16.67%;
				text-align: center;
			}
			li:first-child, li:nth-child(4) {
				// text-align: left;
			}
			/* li:nth-child(3) {
				padding-left: 5px;
			} */
      /* li:first-child, li:nth-child(6) {
        overflow: hidden; //超出的文本隐藏
				text-overflow: ellipsis; //溢出用省略号显示
				white-space: nowrap; //溢出则不换行
      } */
		}
	}

  .no-data-box {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    div:first-child {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    .noData {
      margin-top: 3% !important;
    }
  }

  .van-popup {
    width: 80%;
  }
  .records-box {
    width: 100%;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      width: 50%;
      height: 20px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      span {
        padding-top: 20px;
      }
    }
    .records-label {
      text-align: right;
    }
  }
}
</style>
