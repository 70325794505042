<template>
  <div class="user">
    <div class="user-imges">
      <div class="user-imges-label" v-if="dataInfo.waterMeterStatus">
        {{ dataInfo.waterMeterStatus }}
      </div>
      <div class="user-infor">
        <div class="user-infor-new">
          <div class="uin-name" v-if="dataInfo.wuserName">{{ dataInfo.wuserName }}「{{ dataInfo.wuserNo }}-{{ dataInfo.wuserAddress }}」</div>
          <ul class="uin-num">
            <li v-if="dataInfo.idNo">
              <span>身份证号：</span>
              <span>{{ dataInfo.idNo }}</span>
            </li>
            <li v-if="dataInfo.meterBodyNum">
              <span>表号：</span>
              <span>{{ dataInfo.meterBodyNum }}</span>
            </li>
          </ul>

        </div>
        <van-divider class="user-infor-divider" />
        <div class="user-infor-pay">
<!--          <div class="user-infor-pay-row" v-if="dataInfo.isPay">-->
<!--            <div class="uipr-lable">-->
<!--              <span>未缴账单（元）</span>-->
<!--              <em v-if="dataInfo.finalPayTime">最后缴费日期: {{ dataInfo.finalPayTime }}</em>-->
<!--            </div>-->
<!--            <div class="uipr-handl" @click="gotoPaymentPage(dataInfo)">-->
<!--              <span class="uipr-money">{{ isActual(dataInfo.paymentAmount) }}</span>-->
<!--              <div>-->
<!--                <span class="uipr-button-name">立即缴费</span>-->
<!--                <van-icon name="arrow" />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="user-infor-pay-up" v-if="!dataInfo.isPay">-->
<!--            <span>水费已缴清～</span>-->
<!--          </div>-->
<!--          <van-divider class="user-infor-divider" />-->
          <div class="user-infor-pay-row">
            <div class="uipr-lable">
              <span>余额（元）</span>
            </div>
            <div class="uipr-handl" @click="prestoreRecharge(dataInfo)">
              <div>
                <span class="uipr-money">{{ isActual(dataInfo.depositBalance) }}</span>
                <div class="uipr-stay" v-if="dataInfo.depositBalance <= 0">待充值</div>
              </div>
              <div>
                <span class="uipr-button-name">充值缴费</span>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
        <!-- <van-divider class="user-infor-divider" />
        <div class="user-infor-recharge">
          <div class="rightPre" @click="gotoRechargePage(dataInfo)">
            <span class="rightBtn">缴费记录</span>
            <van-icon name="arrow" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { isAmount } from '@/utils/validator';
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    }
  },
  methods: {
    prestoreRecharge(data) {
      this.$router.push({
        path: `/WaterAccount/PreStorage`,
        query: { wuserNo: data.wuserNo }
      });
    },
    // 立即缴费
    async gotoPaymentPage(data) {
      this.$router.push({
        name: 'PaymentPage',
        path: '/QueryPay/PaymentPage',
        query: {
          wuserNo: data.wuserNo
        }
      });
    },
    gotoRechargePage(data) {
      this.$router.push({
        name: 'RechargeRecord',
        path: '/WaterAccount/RechargeRecord',
        query: {
          wuserNo: data.wuserNo
        }
      });
    }
  },
  watch: {
    dataInfo: {
      immediate: true,
      handler(val) {
        this.dataInfo = val;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user {
  .user-imges {
    border-radius: 8px;
    background: url('../../../assets/background/用水账户_未欠费_深蓝背景.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
  }
  .user-imges-label {
    position: absolute;
    top: 14px;
    right: -30px;
    padding: 2px;
    width: 100px;
    font-family: PingFangSC-Medium;
    font-size: 10px;
    color: #fff;
    text-align: center;
    background: #e71639;
    z-index: 999;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &-infor {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-new {
      display: flex;
      flex-wrap: wrap;
      margin: 16px 16px 0 16px;
      color: #ffffff;
      .uin-name {
        padding: 0 8px 8px 0;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        font-weight: 700;
      }
      .uin-num {
        li:first-child {
          margin-bottom: 8px;
        }
      }
      .uin-division {
        font-size: 12px;
      }
      .uin-card {
        padding-bottom: 8px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: 700;
      }
    }
    &-address {
      margin: 0 16px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
    }
    &-divider {
      color: rgba(255, 255, 255, 0.14);
      border-color: rgba(255, 255, 255, 0.14);
      margin: 10px 0;
    }
    &-pay {
      margin-bottom: 16px;
      &-row {
        .uipr-lable {
          display: flex;
          justify-content: space-between;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.65);
          margin: 0 16px;
          em {
            font-style: normal;
            font-size: 12px;
          }
        }
        .uipr-handl {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #ffffff;
          margin: 10px 16px 0 16px;
          div {
            display: flex;
            align-items: center;
          }
        }
        .uipr-money {
          font-family: PingFangSC-Medium;
          font-size: 20px;
        }
        .uipr-stay {
          background: rgba(255, 255, 255, 0.15);
          border-radius: 100px 100px 100px 0;
          padding: 2px 8px;
          margin-left: 10px;
          font-family: PingFangSC-Regular;
          font-size: 11px;
          color: #ffffff;
        }
        .uipr-button-name {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          margin-right: 6px;
        }
      }
      .user-infor-pay-up {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.65);
      }
    }
    &-recharge {
      color: #ffffff;
      padding-top: 6px;
      padding-bottom: 16px;
      .rightPre {
        margin-right: 16px;
        float: right;
        .rightBtn {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          margin-right: 6px;
        }
        .van-icon {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
